














































































































import Bop from "@/models/Bop";
import OperationService from "@/services/OperationService";
import {
  BopModelEnum,
  CoefficientTypeEnum,
  OperationType,
} from "@/utils/Enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
import BopService from "@/services/BopService";
import CoefficientService from "@/services/CoefficientService";
import Coefficient from "@/models/Coefficient";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import BopModelService from "@/services/BopModelService";
import OperationGroup from "@/models/OperationGroup";
import { evaluate } from "mathjs";
import BopModel from "@/models/BopModel";
import OperationTypeService from "@/services/OperationTypeService";

@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue"),
    OperationComponent: () =>
      import("@/components/BopSetup/shared/OperationComponent.vue"),
  },
})
export default class operationPrincipalStep extends Vue {
  @Prop()
  private isNotReadOnly!: boolean;
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get isEditable(): boolean {
    return this.canBeEditedAfterRealised || this.isNotReadOnly;
  }
  private haveT1Coefficients: boolean = false;
  private operationGroups: Array<any> = [];
  private operationGroupCoefficientFields: Array<any> = [];
  private selectedCoeffGroup: any = {};
  // operation groupe coefficient fields selected values
  private ogCoefficientFieldsSelectedVals: Array<any> = [];
  private globalCoefficients: any = {};
  private unitValuePointIsDefinedByCoefficient: boolean = false;
  private columns: Array<any> = [];
  private columnCoefficients: Array<any> = [];
  private operationFamilyOptions: Array<any> = [];
  private operationGroupsTotalPoints: any = {};
  private selectedGlobalCoefficients: any = {};

  private get bopShort(): String {
    return `${
      this.bop.siteName
    } - ${this.bop.bopModelName?.toString().toLowerCase()} ${
      this.bop.bopNumber ? " # " : ""
    }${this.bop.bopNumber ?? ""}`;
  }

  private fieldCoefficient: Array<any> = [];
  private get language(): string {
    return this.$i18n.locale;
  }
  private get selectedBopModel(): BopModel {
    return this.$store.state.selectedBopModel;
  }

  private get allTotalPoints() {
    return this.bop.totalOperationPrincipalPoints;
  }
  private get canManageOperationGroups() {
    const bopModelKey = this.bop.bopModelKey;
    return (
      bopModelKey === BopModelEnum.CalorifugageTuyauterie ||
      this.isBopTuyauteriePlastique
    );
  }
  private get isBopTuyauteriePlastique(): boolean {
    return this.bop.bopModelKey === BopModelEnum.TuyauteriePlastique;
  }
  private get bop() {
    return this.$store.state.bop;
  }
  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  private get isGlobalCoefficientsVisible() {
    return Object.keys(this.globalCoefficients).length;
  }
  private get canHaveOperationFamilyColumnfilter(): boolean {
    return (
      this.selectedBopModel &&
      this.selectedBopModel.key === BopModelEnum.GenieCivil
    );
  }
  private get isLoaded(): boolean {
    const length = this.operationGroups.filter(
      (og: OperationGroup) => !og.canBeDeleted
    ).length;
    return this.operationGroupCounter === length;
  }
  private operationGroupCounter: number = 0;
  private get isBopReadOnly(): boolean {
    return (
      Bop.readOnlyStatuses.includes(this.bop.bopStatusKey) &&
      !this.canBeEditedAfterRealised
    );
  }
  /**
   * ###########
   * methods
   * ###########
   * */
  private onFullyLoaded() {
    // this.operationGroupCounter++;
    // if (this.isLoaded) {
    this.$emit("fully-loaded");
    // }
  }
  private refreshOperationGroupNumber() {
    let counter = 0;
    this.operationGroups.forEach((og: OperationGroup) => {
      if (!og.canBeDeleted) {
        counter++;
        og.number = counter;
      }
    });
  }
  private removeOperationGroup(operationGroupIndex: number) {
    if (!this.isEditable) return;
    (<OperationGroup>(
      this.operationGroups[operationGroupIndex]
    )).canBeDeleted = true;
    this.$store.commit("removeBopOperationPrincipalGroup", operationGroupIndex);
    this.refreshOperationGroupNumber();
  }
  private onGlobalCoefficientSelectChange($event: any, coeff: any) {
    Vue.set(this.selectedGlobalCoefficients, coeff.coeffKey, $event);
    this.$forceUpdate();
  }
  private addOperationGroup() {
    let operationGroup = new OperationGroup();
    const lastElement = this.operationGroups[this.operationGroups.length - 1];

    operationGroup.number = lastElement.number + 1;
    this.ogCoefficientFieldsSelectedVals.push(...[this.selectedCoeffGroup]);

    this.bop.operationPrincipalGroups.push(operationGroup);
    this.operationGroups.push(operationGroup);
  }

  private onOperationChange(operationDictionary: any) {
    const operationGroupIndex = this.bop.operationPrincipalGroups.findIndex(
      (og) => og.id === operationDictionary.key
    );
    const operations = operationDictionary.value;
    this.$store.commit("changeBopOperationPrincipal", {
      operationGroupIndex,
      operations,
    });
    // this.bop.operationPrincipalGroups[
    //   operationGroupIndex
    // ].operations = operations;
    // console.groupEnd();
  }

  private async onTotalPointsChanged(totalPoints: any) {
    this.$set(
      this.operationGroupsTotalPoints,
      totalPoints.key,
      totalPoints.value
    );
    // this.operationGroupsTotalPoints[totalPoints.key] = totalPoints.value;
  }
  private calculate() {
    if (!this.isEditable) return;
    const calculusFunction = this.selectedBopModel.functionTotalUnitPoints;
    if (!calculusFunction) return;

    const sum = <number>(
      Object.values(this.operationGroupsTotalPoints).reduce(
        (accumulator, currentValue) =>
          <number>accumulator + <number>currentValue,
        0
      )
    );
    if (calculusFunction === "{sum}") {
      const result = Math.round((sum + Number.EPSILON) * 100) / 100;
      this.$store.commit("changeTotalOperationPrincipalPoints", sum);
      return;
    }
    const regex = /{[\w.]*}/gm;
    let m;
    let formulaElements: Array<any> = [];
    while ((m = regex.exec(calculusFunction)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      for (let i = 0; i < m.length; i++) {
        const match = m[i];
        const path: string = match.replace(/{|}/gm, "");
        //replacing the {key} with the corresponding value
        let value = "";
        if (path.indexOf("globalCoefficient") !== -1) {
          const lastIndexOfDot = path.indexOf(".");
          const key = path.substring(lastIndexOfDot + 1);
          /**
           * if the coefficient is not yet selectionned no need to continue further calculus and parsing
           */
          if (!this.$store.state.selectedGlobalCoefficients[key]) {
            this.$store.commit("changeTotalOperationPrincipalPoints", 0);
            return;
          }
          value = this.$store.state.selectedGlobalCoefficients[key].value;
        } else if (path === "sum") {
          value = sum.toString();
        }
        formulaElements.push({
          key: match,
          value,
        });
      }
    }
    let equation = calculusFunction;
    formulaElements.forEach((el: any) => {
      equation = equation.replace(el.key, el.value);
    });
    let result = evaluate(equation);
    result = Math.round((result + Number.EPSILON) * 100) / 100;

    this.$store.commit("changeTotalOperationPrincipalPoints", result);
    // console.groupEnd();
  }
  /**
   * load the component data
   */
  private async loadData() {
    if (
      !this.bop.bopModelId ||
      !this.bop.operationPrincipalGroups ||
      !this.selectedBopModel
    )
      return;
    if (this.canHaveOperationFamilyColumnfilter) {
      const opFamilies = Object.values(
        (
          await OperationTypeService.ListOperationTypesWithDescendenants(
            1,
            this.selectedBopModel.id,
            this.language
          )
        ).data
      );
      this.operationFamilyOptions = opFamilies;
    }
    if (!this.bop.operationPrincipalGroups.length) {
      this.bop.operationPrincipalGroups = [new OperationGroup()];
    } else if (
      this.bop.operationPrincipalGroups.every(
        (og: OperationGroup) => og.canBeDeleted
      )
    ) {
      this.bop.operationPrincipalGroups.push(new OperationGroup());
    }
    const columnCoefficients: Array<any> = [];
    const columnCoefficientsDefiningOperations: Array<any> = [];
    for (let key in this.bop.selectedGlobalCoefficients) {
      this.$set(
        this.selectedGlobalCoefficients,
        key,
        this.bop.selectedGlobalCoefficients[key].coefficientOptionId
      );
    }
    this.$store.commit(
      "changeSelectedGlobalCoefficients",
      this.bop.selectedGlobalCoefficients
    );
    const fieldCoefficient: Array<any> = [];
    const coefficients = (
      await CoefficientService.getBopModelCoefficientTypes(
        this.bop.bopModelId,
        this.language
      )
    ).data;
    const operationGroupCoefficientFields: Array<any> = [];
    const globalCoefficients: any = {};
    // let selectedCoeffGroup: any = {};
    for (let i = 0; i < coefficients.length; i++) {
      const coeff: Coefficient = coefficients[i];
      let coefficientOptions = null;
      if (coeff.coefficientType !== CoefficientTypeEnum.T1) {
        coefficientOptions = (
          await CoefficientOptionService.GetCoefficientOptionsByCoefficient(
            coeff.id,
            this.language
          )
        ).data.map((el: any) => {
          const value =
            coeff.coefficientType == CoefficientTypeEnum.T2
              ? el.value
              : el.name;
          return {
            key: el,
            value,
          };
        });
      } else {
        this.haveT1Coefficients = true;
      }

      if (coeff.coefficientType === CoefficientTypeEnum.T4) {
        let opGlobalField: any = {};
        //  if (coeff.coefficientType == CoefficientTypeEnum.T0) {
        opGlobalField = {
          id: coeff.id,
          name: coeff.name,
          coeffKey: coeff.key,
          coefficientType: coeff.coefficientType,
          options: coefficientOptions,
        };

        globalCoefficients[coeff.key] = opGlobalField;
      }
      // if the coefficient is per line not per group
      else if (!coeff.isOperationGroupCoefficient) {
        if (coeff.coefficientType == CoefficientTypeEnum.T0) {
          columnCoefficients.push({
            name: "coefficientEditable",
            title: coeff.name,
            coefficient: coeff,
            property: `coefficientSelectedValues.${coeff.key}.value`,
            dataContainer: `coefficients.${coeff.key}.value`,
            isNotEditable: !this.isEditable,
          });
          fieldCoefficient.push({ key: coeff.key, id: coeff.id });
        } else if (
          coeff.coefficientType == CoefficientTypeEnum.T2 ||
          coeff.coefficientType == CoefficientTypeEnum.T3
        ) {
          if (
            !this.unitValuePointIsDefinedByCoefficient &&
            coeff.definesOperationUnitPoint
          ) {
            fieldCoefficient.push({ key: coeff.key, id: coeff.id });
            this.unitValuePointIsDefinedByCoefficient = true;
            columnCoefficientsDefiningOperations.push({
              name: "bopCoefficientDropDown",
              title: coeff.name,
              property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
              // display: "operationName",
              coefficient: coeff,
              // options: coefficientOptions,
              dataContainer: `coefficients.${coeff.key}`,
              isNotEditable: !this.isEditable,
            });
          } else {
            fieldCoefficient.push({ key: coeff.key, id: coeff.id });
            columnCoefficients.push({
              name: "bopCoefficientDropDown",
              title: coeff.name,
              property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
              // display: "operationName",
              coefficient: coeff,
              // options: coefficientOptions,
              dataContainer: `coefficients.${coeff.key}`,
              isNotEditable: !this.isEditable,
            });
          }
        }
      } else {
        // if it is a group coefficient
        let opGroupField: any = {};
        //  if (coeff.coefficientType == CoefficientTypeEnum.T0) {
        fieldCoefficient.push({ key: coeff.key, id: coeff.id });
        opGroupField = {
          id: coeff.id,
          name: coeff.name,
          coeffKey: coeff.key,
          coefficientType: coeff.coefficientType,
          // property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
          // dataContainer: `coefficients.${coeff.key}`,
        };
        if (
          coeff.coefficientType == CoefficientTypeEnum.T2 ||
          coeff.coefficientType == CoefficientTypeEnum.T3
        ) {
          opGroupField["options"] = coefficientOptions;
        }
        // } else

        // selectedCoeffGroup[coeff.key] = "";
        operationGroupCoefficientFields.push(opGroupField);
      }
    }

    await Vue.nextTick();
    this.fieldCoefficient = fieldCoefficient;
    // this.selectedCoeffGroup = {...selectedCoeffGroup};
    this.operationGroupCoefficientFields = operationGroupCoefficientFields;
    this.globalCoefficients = globalCoefficients;
    this.operationGroups = JSON.parse(
      JSON.stringify(this.bop.operationPrincipalGroups)
    );
    // const ogCoefficientFieldsSelectedVals: Array<any> = new Array(this.operationGroups.length);
    // ogCoefficientFieldsSelectedVals.fill(selectedCoeffGroup);
    // this.ogCoefficientFieldsSelectedVals = ogCoefficientFieldsSelectedVals;
    this.refreshOperationGroupNumber();
    const columns: Array<any> = [
      {
        name: "quantity",
        title: this.$t("bopSetup.operationQuantity").toString(),
        property: "quantity",
        isNotEditable: !this.isEditable,
      },
    ];
    if (!this.unitValuePointIsDefinedByCoefficient) {
      if (this.haveT1Coefficients) {
        columns.push({
          name: "disabledDynamicInput",
          title: this.$t("bopSetup.operationUnitPoints").toString(),
          property: "operationUnitPoints",
          formula: this.selectedBopModel.functionOperationUnitPoints,
          titleClass: "hidden",
          dataClass: "hidden",
        });
      } else {
        columns.push({
          name: "disabledDynamicInput",
          title: this.$t("bopSetup.operationUnitPoints").toString(),
          formula: "operation.operationUnitValue",
          titleClass: "hidden",
          dataClass: "hidden",
        });
      }
    } else {
      columns.push(...columnCoefficientsDefiningOperations);
      columns.push({
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.operationUnitPoints").toString(),
        formula: "operation.operationUnitValue",
        titleClass: "hidden",
        dataClass: "hidden",
      });
    }
    if (columnCoefficients.length) {
      columns.push(...columnCoefficients);
    }
    columns.push({
      name: "disabledDynamicInput",
      title: this.$t("bopSetup.total").toString(),
      property: "totalPoints",
      // formula: "{operation.operationUnitValue} * {quantity} * {coefficients.K1.value}",
      formula: this.selectedBopModel.functionUnitPoints,
    });
    this.columns = columns;
    this.operationGroupsTotalPoints = {};
  }
  /**
   * Hooks
   */
  private created() {
    this.loadData().then(() => {});
  }

  /**
   * ########
   * Watches
   * ########
   */
  @Watch("selectedBopModel.key")
  private async onBopModelChange() {
    await this.loadData();
  }
  @Watch("selectedGlobalCoefficients", { deep: true })
  private async onSelectedGlobalCoefficients() {
    if (!this.isGlobalCoefficientsVisible || this.isBopReadOnly) return;
    let selectedGlobalCoeffs = {};
    //this.globalCoefficients.find(el=> el.options.key.id === this.selectedGlobalCoefficients))
    // this.bop.selectedGlobalCoefficients = this.selectedGlobalCoefficients;
    if (!Object.keys(this.globalCoefficients).length) return;
    for (let key in this.selectedGlobalCoefficients) {
      if (!this.globalCoefficients[key]) continue;
      const val = this.selectedGlobalCoefficients[key];
      const coeff = this.globalCoefficients[key].options.find(
        (op) => op.key.id === val
      )?.key;
      selectedGlobalCoeffs[key] = coeff;
      if (!this.bop.selectedGlobalCoefficients[key]) {
        this.bop.selectedGlobalCoefficients[key] = {};
      }
      this.bop.selectedGlobalCoefficients[key].CoefficientOptionId = coeff?.id;
      this.bop.selectedGlobalCoefficients[key].CoefficientId =
        coeff?.coefficientId;
      this.bop.selectedGlobalCoefficients[key].value = coeff?.value;
    }
    this.$store.commit(
      "changeSelectedGlobalCoefficients",
      selectedGlobalCoeffs
    );
    this.calculate();
  }
  @Watch("language")
  private onLanguageChange() {
    this.loadData();
  }
  @Watch("operationGroupsTotalPoints", { deep: true })
  private onOperationGroupsTotalPoints() {
    this.calculate();
  }
}
